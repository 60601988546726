import React from "react"
import ReactPlayer from "react-player"
import "bootstrap/dist/css/bootstrap.min.css"
import KnowUs from "./KnowUs"
import "./blog.css"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid } from "@material-ui/core"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import Blink from 'react-blink-text';

import IBB from "../../assets/video/IBB.mp4"
import IBB1 from "../../assets/video/IBB1.mp4"

import alumni11 from "../../assets/video/alumni11.mp4"
import ss1 from "../../assets/images/ss1.png"
import ss2 from "../../assets/images/ss2.png"
import ss3 from "../../assets/images/ss3.png"
import ss4 from "../../assets/images/ss4.png"
import ss5 from "../../assets/images/ss5.png"
import ss53 from "../../assets/images/ss53.png"


import s1 from "../../assets/icons/s1.png"
import s2 from "../../assets/icons/s2.png"
import s3 from "../../assets/icons/s3.png"
import s4 from "../../assets/icons/s4.png"
import s52 from "../../assets/icons/s52.png"
import s53 from "../../assets/icons/s53.png"

import hor1 from "../../assets/images/hor1.png"
import hor2 from "../../assets/images/hor2.png"
import hor3 from "../../assets/images/hor3.png"
import hor4 from "../../assets/images/hor4.png"

import ReactTypingEffect from "react-typing-effect"
import { Helmet } from "react-helmet"


const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 31.29624, lng: 75.593571 }}
    >
      <Marker position={{ lat: 31.29624, lng: 75.593571 }} />
    </GoogleMap>
  ))
)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

export const Home = () => {
  const classes = useStyles()
  return (
    <>
    <div>
    <Helmet>
    
    <title>
      Home | Cambridge International Foundation School 

         </title>
       
    </Helmet>

    </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "0px",
        }}
      >
        <video
          loop
          autoPlay
          muted
          style={{
            width: "100%",
          }}
        >
          <source src={IBB1} type="video/mp4" />
        </video>
          
      </div>
      <div className="row alumniVideo">
        {[
          { src1: s1, to: "/curriculum", src2: ss1 },
          {
            src1: s2,
            to: "/infrastructure",
            src2: ss2,
          },
          {
            src1: s53,
            to: "/sat",
            src2: ss53,
          },
          { src1: s4, to: "/faculty", src2: ss4 },
          { src1: s52, to: "/admission", src2: ss5 },
        ].map(item => (
          <div className="col" id="bordering6" className="container6">
            <img src={item.src1} alt="pyp" />
            {item.to ? (
              <div className="overlay6">
                <Link to={item.to} target="_blank">
                  <img src={item.src2} alt="pyp" />
                </Link>
              </div>
            ) : (
              <div className="overlay6">
                <a href={item.href} target="_blank">
                  <img src={ss53} alt="pyp" />
                </a>
              </div>
            )}
          </div>
        ))}
      </div>

      <Grid container spacing={1} align="center">
        <Grid item xs={14} sm={4} className="missionBackground">
          <img src={require("../../assets/icons/bg3.png")} />
        </Grid>
        <Grid item xs={16} sm={8} className="missionContainer homeAlign">
          <h5
            style={{
              color: "#e95721",
              fontFamily: "sans-serif",
              fontSize: "35px",
            }}
          >
            OUR MISSION
          </h5>
          <p
            className="card-text text-secondary"
            style={{
              padding: "0px 150px",
              fontSize: "16px",
            }}
          >
            The mission of Learning Wings Education Systems is to nurture the
            development of responsible, thoughtful citizens for life in an
            increasingly interdependent global society by creating environments
            in which students are challenged to explore, to create and to make
            decisions. Mission of LWES is manifested in our students through
            continuous practices of learning where each student is nurtured to
            be free of imposed prejudices, access opportunities to lead,
            participate beyond stereotypes, accept challenges and compete to
            succeed.
          </p>
          <Link to="./aboutus" target="_blank">
            <p
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {" "}
              More⇝
            </p>
          </Link>
        </Grid>
      </Grid>

      
      <div className="container8 " style={{ paddingTop: "10px" , paddingLeft:"10px"}}>
        <img src={hor4} alt="pyp" style={{display: "inline"}} />
        <div className="centered">
          <ReactTypingEffect
            className="typingeffect"
            text={["Rooted in traditions,looking ever onwards"]}
            speed={100}
            eraseDelay={600}
          />
          <br />
        </div>
        <div className="centered" style={{ paddingTop: "60px" }}>
          <Link to="./corevalues" target="_blank">
            <Button className="button6" variant="inherit">
              <b>CORE VALUES ⇝</b>
            </Button>{" "}
          </Link>
        </div>
      </div>

      <Grid container spacing={1} className="alumniVideo" style={{ margin: "10px 0px" }}>
        <Grid item sm={12} xs={12} md={6}>
          <ReactPlayer
            url="https://youtu.be/E4mtejO9q5o?si=gMWbXGzlCwJMpnxr"
            width="100%"
          />
        </Grid>

        <Grid item sm={12} xs={12} md={6}>
          <ReactPlayer
            url="https://youtu.be/bv_4ptncV08"
            width="100%"
          />
        </Grid>
      </Grid>

      <KnowUs />

      <div className="container8" style={{paddingTop:"20px", paddingLeft:"30px"}}>
        <img src={hor3} alt="pyp" />

        <div className="centered">
          <ReactTypingEffect
            className="typingeffect"
            text={["Trending @ CIFS"]}
            speed={100}
            eraseDelay={600}
          />
          <br />
        </div>
        <div className="centered" style={{ paddingTop: "60px" }}>
          <Link to="./storyhighlights" target="_blank">
            <Button className="button6" variant="inherit">
              <b>NEWSFEED ⇝</b>
            </Button>{" "}
          </Link>
        </div>
      </div>

      <Grid container justify="center" style={{ margin: "30px 0px" }}>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          <h5
            style={{
              color: "#e95721",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              fontSize: "35px",
              paddingBottom: "10px",
            }}
          >
            ALUMNI SPEAK
          </h5>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          <p className="homeAlign" style={{ paddingBottom: "10px" }} >
            <i style={{ fontSize: "150%", color: "#0ca685" }}>
              Take a look at Cambridgeans around the globe making a difference
              in one way or the other.
            </i>
          </p>
        </Grid>
       
          <Grid item xs={12} sm={12} md={6}>
            <video

              loop
              autoPlay
              muted
              style={{
                
                width: "100%",
              }}
            >
              <source src={alumni11} type="video/mp4" />
            </video>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className=" missionContainer homeAlign">
            <p
              className="card-text text-secondary "
              style={{
                color: "#8C001A",
                textAlign: "center",
              }}
            >
              The success of alumni at national or inter-national level speaks
              volumes about the reputation of an institute. Alumni play a
              valuable role in increasing the brand-value of an institute
              through words-of-mouth.
              <br />
              As one faculty member said, “{" "}
              <i>
                It seems that our students go out and do whatever it is they
                want to do in life.
              </i>
              ” That’s exactly what we want for out students, to live rewarding
              lives, feel proud of their accomplishments, and to make the world
              a more humane and exquisite place.
            </p>
            <div align="center">
              <Link to="./alumni" target="_blank">
                <Button className="button6" variant="inherit">
                  Find out more ⇝
                </Button>{" "}
              </Link>
            </div>
          </Grid>
        
      </Grid>

      <Grid 
      
        container
        justify="center"
      >
        <h1

          style={{
            color: "#e95721",
            fontWeight: "bold",
            fontSize: "35px",
            fontFamily: "sans-serif",
          }}
        >
          CAMBRIDGE COMMUNITY
        </h1>

        <p 
          className="card-text text-secondary homeAlign"
          style={{ fontSize: "16px", textAlign: "center", paddingLeft:"30px", paddingRight:"30px"  }}
        >
          Cambridge International Foundation School is a part of the Learning
          Wings family. Learning Wings is a global organisation devoted to
          high-quality education that meets the demands of parents, students and
          education providers the world over. It is also a leading provider of
          school management services and works closely with schools to raise
          student achievements. It's flagship schools - Cambridge International
          School - spread across North India is showing children the process of
          realizing their potential.
        </p>
        
        <Grid container justify="center" style={{padding:"20px 50px"}}>
          <Grid 
            item
            md={2}
            sm={6}
            xs={6}
            style={{ margin: "2px 50px" }}
            justify="center"
           className="img-hover-zoom--colorizes"
          >
            <a href="https://www.cisfgjal.school/" target="_blank">
              <img
                style={{
                  border: "solid #fcbc3c",
                  width: "250px",
                  height: "230px",
                  margin: "auto",
                }}
                src={require("../../assets/images/girls.jpg")}
                alt="Our-Story"
                loader="gradient"
                gradientPreset="life"
              />
            </a>
            <h3
              className="schoolBuilding"
              align="center"
              style={{
                paddingTop:"20px",
                color: "#0ca685",
              fontSize: "13px",
              }}
            >
              Cambridge Innovative <br />
              School, Jalandhar
            </h3>
          </Grid>
          <Grid 
          className="schoolBuilding"
            item
            md={2}
            sm={6}
            xs={6}
            style={{ margin: "2px 50px" }}
            justify="center"
             className="img-hover-zoom--colorizes"
          >
            <a href="https://cifsjalandhar.com/#/">
              <img
                style={{
                  border: "solid #fcbc3c",
                  width: "250px",
                  height: "230px",
                  margin: "auto",
                }}
                src={require("../../assets/images/Building.jpg")}
                alt="Our-Story"
                loader="gradient"
                gradientPreset="life"
              />
            </a>
            <h3
            className="schoolBuilding"
              align="center"
              style={{
                color: "#0ca685",
                paddingTop:"20px",
                fontSize: "13px",
              }}
            >
              Cambridge International <br />
              Foundation School, Jalandhar
            </h3>
          </Grid>
          <Grid
          className="schoolBuilding"
            item
            md={2}
            sm={6}
            xs={6}
            style={{ margin: "2px 50px" }}
            justify="center"
             className="img-hover-zoom--colorizes"
            
          >
            <a href="http://www.cambridgemohali.com/" target="_blank">
              <img
                style={{
                  border: "solid #fcbc3c",
                  width: "250px",
                  height: "230px",
                  margin: "auto",
                }}
                src={require("../../assets/images/mohali1.jpg")}
                alt="Our-Story"
                loader="gradient"
                gradientPreset="life"
              />
            </a>
            <h3
            className="schoolBuilding"
              align="center"
              style={{
                color: "#0ca685",
                paddingTop:"20px",
                fontSize: "13px",
              }}
            >
              Cambridge International <br />
              School, Mohali
            </h3>
          </Grid>
          <Grid
          className="schoolBuilding"
            item
            md={2}
            sm={6}
            xs={6}
            style={{ margin: "2px 0px" }}
            justify="center"
            className="img-hover-zoom--colorizes"
          >
            
          </Grid>
         
        </Grid>
        <img
          src={require("../../assets/icons/dais.JPG")}
          style={{ marginLeft: "1200px" }}
          alt="line"
          width="170rem"
          height="160rem"
        />
      </Grid>

      <br/>
       


      <MapComponent
        style={{
          margin: "10px 2px",
        }}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDXoNnzaAoLMctYUbh966PLVDBlcTPpcWo"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `250px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </>
  )
}
