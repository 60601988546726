import React from "react"
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import { Home } from "../components/home/home"
import { Layout } from "../components/layout"
import { Policy } from "../components/policy/policy-and-procedure"
import Infrastructure from "../components/infrastructure/infrastructure"
import { Curriculum } from "../components/curriculum/curriculum"
import { OurStory } from "../components/ourStory/OurStory"
import { Fees } from "../components/fees/pay-fees-online"
import { Organisation } from "../components/organisation/organisation"
import { Achievements } from "../components/home/achievements"


import { ArtAndCraft } from "../components/infrastructure/art-and-craft"
import { IctLab } from "../components/infrastructure/Ict-lab"
import { DanceMusic } from "../components/infrastructure/dance-music"
import { Library } from "../components/infrastructure/Library"
import { MathsLab } from "../components/infrastructure/maths-lab"
import { ScienceLab } from "../components/infrastructure/science-lab"
import { Security } from "../components/infrastructure/Security"
import { Sports } from "../components/infrastructure/Sports"
import { Admission } from "../components/admission/admission"
import { Contact } from "../components/admission/contact"
import { Blog } from "../components/home/blog"
import { Alumni } from "../components/home/alumni"
import { AboutUs } from "../pages/aboutus"
import { PrincipalPage } from "../pages/principal-message"
import { ChairmanPage } from "../pages/chairman"
import { CAO } from "../pages/CAO"
import { Policies } from "../pages/policies"
import { EarlyyearsPage } from "../pages/earlyyearsdean"

import { EarlyYears } from "../pages/earlyyears"
import { Pyp } from "../pages/pyp"
import { Caie } from "../pages/caie"
import { Ibdp } from "../pages/ibdp"
import { StoryHighlights } from "../components/home/storyhighlights"
import { CasProjects } from "../components/home/casprojects"
import { coreValues } from "../components/home/corevalues"
import GridWrapper from "../components/common/grid-wrapper"
import GridWrapper2 from "../components/common/grid-wrapper2"
import GridWrapper3 from "../components/common/grid-wrapper3"
import GridWrapper4 from "../components/common/grid-wrapper4"
import { Calender } from "../pages/calender"
import { Faculty } from "../pages/faculty"
import { Sat } from "../pages/sat"




import { NewsLetter } from "../components/home/newsletter"
import { SummerCamp } from "../components/home/summercamp"
import { AnnualFunction } from "../components/home/annualfunction"


import hor1 from "../assets/images/hor1.png"
import t1 from "../assets/titleimage/t1.png"
import t2 from "../assets/titleimage/t2.png"
import t3 from "../assets/titleimage/t3.png"
import t4 from "../assets/titleimage/t4.png"
import ttemp from "../assets/titleimage/ttemp.png"
import t6 from "../assets/titleimage/t6.png"
import t7 from "../assets/titleimage/t7.png"
import t8 from "../assets/titleimage/t8.png"
import t13 from "../assets/titleimage/t13.png"
import t9 from "../assets/titleimage/t9.png"
import t10 from "../assets/titleimage/t10.png"
import t11 from "../assets/titleimage/t11.png"
import t12 from "../assets/titleimage/t12.png"
import t121 from "../assets/titleimage/t121.png"
import t14 from "../assets/titleimage/t14.jpg"
import t15 from "../assets/titleimage/t15.png"
import t16 from "../assets/titleimage/t16.png"
import t17 from "../assets/titleimage/t17.png"
import t18 from "../assets/titleimage/t18.png"
import t19 from "../assets/titleimage/t19.png"
import t22 from "../assets/titleimage/t22.png"
import t23 from "../assets/titleimage/t23.png"
import t26 from "../assets/titleimage/t26.png"
import t27 from "../assets/titleimage/t27.png"
import t28 from "../assets/titleimage/t28.png"
import t29 from "../assets/titleimage/t29.png"
import t30 from "../assets/titleimage/t30.png"
import t31 from "../assets/titleimage/t31.png"
import t32 from "../assets/titleimage/t32.png"
import t34 from "../assets/titleimage/t34.png"




const IndexPage = () => (
  <Router>
    <Layout>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/OurStory" component={OurStory} exact />

        <Route
          path="/aboutus"
          render={props => (
            <GridWrapper
              {...props}
              children={AboutUs}
              title={<img src={t3} alt="pyp" />}
            />
          )}
          exact
        />

        <Route
          path="/policyAndProcedure"
          render={props => (
            <GridWrapper
              {...props}
              children={Policy}
              title={<img src={t28} alt="policy" />}
            />
          )}
          exact
        />

        <Route
          path="/chairman"
          render={props => (
            <GridWrapper
              {...props}
              children={ChairmanPage}
              title={<img src={t10} alt="chairman" />}
            />
          )}
          exact
        />

        <Route
          path="/faculty"
          render={props => (
            <GridWrapper
              {...props}
              children={Faculty}
              title={<img src={t26} alt="faculty" />}
            />
          )}
          exact
        />





        <Route
          path="/earlyyearsdean"
          render={props => (
            <GridWrapper
              {...props}
              children={EarlyyearsPage}
              title={<img src={t31} alt="EarlyyearsPage" />}
            />
          )}
          exact
        />

        <Route
          path="/principal-message"
          render={props => (
            <GridWrapper
              {...props}
              children={PrincipalPage}
              title={<img src={ttemp} alt="PrincipalPage" />}
            />
          )}
          exact
        />

        <Route
          path="/cao"
          render={props => (
            <GridWrapper
              {...props}
              children={CAO}
              title={<img src={t9} alt="CAO" />}
            />
          )}
          exact
        />

        

        <Route
          path="/infrastructure"
          render={props => (
            <GridWrapper2
              {...props}
              children={Infrastructure}
              title={<img src={t11} alt="Infrastructure" />}
            />
          )}
          exact
        />

        <Route
          path="/calender"
          render={props => (
            <GridWrapper2
              {...props}
              children={Calender}
              title={<img src={t19} alt="Calender" />}
            />
          )}
          exact
        />

        <Route
          path="/curriculum"
          render={props => (
            <GridWrapper2
              {...props}
              children={Curriculum}
              title={<img src={hor1} alt="Curriculum" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/library"
          render={props => (
            <GridWrapper4
              {...props}
              children={Library}
              title={<img src={t18} alt="Library" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/art-and-craft"
          render={props => (
            <GridWrapper4
              {...props}
              children={ArtAndCraft}
              title={<img src={t6} alt="ArtAndCraft" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/ict-lab"
          render={props => (
            <GridWrapper4
              {...props}
              children={IctLab}
              title={<img src={t16} alt="IctLab" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/dance-music"
          render={props => (
            <GridWrapper4
              {...props}
              children={DanceMusic}
              title={<img src={t15} alt="DanceMusic" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/maths-lab"
          render={props => (
            <GridWrapper4
              {...props}
              children={MathsLab}
              title={<img src={hor1} alt="MathsLab" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/science-lab"
          render={props => (
            <GridWrapper4
              {...props}
              children={ScienceLab}
              title={<img src={t14} alt="ScienceLab" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/security"
          render={props => (
            <GridWrapper4
              {...props}
              children={Security}
              title={<img src={t17} alt="Security" />}
            />
          )}
          exact
        />

        <Route
          path="/infrastructure/sports"
          render={props => (
            <GridWrapper4
              {...props}
              children={Sports}
              title={<img src={t7} alt="Sports" />}
            />
          )}
          exact
        />



        <Route
          path="/earlyyears"
          render={props => (
            <GridWrapper
              {...props}
              children={EarlyYears}
              title={<img src={t32} alt="Early Years" />}
            />
          )}
          exact
        />



        <Route
          path="/pyp"
          render={props => (
            <GridWrapper
              {...props}
              children={Pyp}
              title={<img src={t8} alt="Pyp" />}
            />
          )}
          exact
        />

        <Route
          path="/caie"
          render={props => (
            <GridWrapper
              {...props}
              children={Caie}
              title={<img src={t13} alt="Caie" />}
            />
          )}
          exact
        />

        <Route
          path="/ibdp"
          render={props => (
            <GridWrapper
              {...props}
              children={Ibdp}
              title={<img src={t121} alt="Ibdp" />}
            />
          )}
          exact
        />

        <Route
          path="/payFeesOnline"
          render={props => (
            <GridWrapper
              {...props}
              children={Fees}
              title={<img src={t1} alt="fees" />}
            />
          )}
          exact
        />

        <Route
          path="/admission"
          render={props => (
            <GridWrapper
              {...props}
              children={Admission}
              title={<img src={t30} alt="admission" />}
            />
          )}
          exact
        />

        <Route
          path="/contact"
          render={props => (
            <GridWrapper
              {...props}
              children={Contact}
              title={<img src={t29} alt="contact" />}
            />
          )}
          exact
        />


          <Route
          path="/sat"
          render={props => (
            <GridWrapper
              {...props}
              children={Sat}
              title={<img src={t34} alt="contact" />}
            />
          )}
          exact
        />



        <Route path="/organisation" component={Organisation} exact />

        <Route path="/storyhighlights" component={StoryHighlights} exact />
        <Route path="/achievements" component={Achievements} exact />
        <Route path="/casprojects" component={CasProjects} exact />
        <Route path="/corevalues" component={coreValues} exact />
        <Route path="/newsletter" component={NewsLetter} exact />
        <Route path="/summercamp" component={SummerCamp} exact />
        <Route path="/annualfunction" component={AnnualFunction} exact />
        <Route path="/policies" component={Policies} exact />
        


        <Route path="/alumni" component={Alumni} exact />
        <Route path="/blog" component={Blog} exact />
      </Switch>
    </Layout>
  </Router>
)

export default IndexPage
