import React from "react"
import { Grid } from "@material-ui/core"
import Table from "react-bootstrap/Table"
import { Helmet } from "react-helmet"


export const Faculty = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Faculty | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Faculty
        </h1>
        <p>
          A well-trained faculty adorns the premises of the school where in they
          guide the students in quite an innovative manner to be the thinkers,
          inquirers, communicators and responsible global citizens. From time to
          time, the teachers attend IB workshops to facilitate their learning
          for professional development. This enables them to share their
          creative transformation experiences with others as well and to equip
          themselves better to mentor their students towards their holistic
          development.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            Staff list of CAIE, PYP &amp; IBDP Teachers
          </i>
        </p>

        <Table striped bordered hover size="sm" responsive="sm">
          <thead>
            <tr
              style={{
                color: "#e95721",
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              <th className="transition"> Department</th>
              <th>Name of the Teachers</th>
            </tr>
          </thead>
          <tbody>




          <tr className="card-text text-secondary">
              <td>Dean Academics </td>
              <td>
                Ms Deepti
               
              </td>
            </tr>



            <tr className="card-text text-secondary">
              <td>ICT</td>
              <td>
                Ms Poonam
                <br />
                Ms Sukhwandana
                <br />
                Ms Ruchi Minhas
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Mathematics</td>
              <td>
                Ms Pankaj Kumari
                <br />
                Ms Minakshi Devi
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Language</td>
              <td>
                Ms Meena Jaswal
                <br />
                Ms Manjit Kaur
                <br />
                Mr Pardeep Kashyap
                <br />
                Ms Inderjeet Kaur
 		<br />
                Ms Mitali Sareen
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Science</td>
              <td>
                Ms Anupam Sandhu
                <br />
                Ms Pushpdeep Kaur
                <br />
                Ms Saivleen Kaur
                <br />
                Ms Anjali
                
                
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Humanities & Social Science/ Individuals & Societies</td>
              <td>
                Ms Nisha Kango
                <br />
                Ms Prabhjot Kaur
              <br />
              Ms Aastha Passi
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Performing Arts/ Visual Arts/ Sports </td>
              <td>
                Ms Rachita Bhalla
                <br />
                Mr Harsh
                <br />
                Ms Amita Badhan
                <br />
               Ms Sonika
               <br />
               Ms Jyoti Sharma
               <br />
               Ms Ritu Spall
               <br />
               Mr Pardeep Kumar
               
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Librarian</td>
              <td>Ms Sonia Kumra
                <br />
                
                  </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>PYP</td>
              <td>
                Ms Sonam Chadha
                <br />
                Mrs. Mallika Sidki
                <br />
                Ms Kamal Chawla
                <br />
                Ms Shilpa Ahuja
                <br />
                Ms Taranpreet Kaur
                <br />
                Ms Aarti Rawat
                <br />
                Ms Inderpreet Kaur
                <br />
                Ms Divya Batra
                <br />
                
		Ms Inderpreet Kaur
		<br />
                Ms Tanjeet Kaur
                

              </td>


            </tr>



<tr className="card-text text-secondary">
              <td>Early Years </td>
              <td>
                
                Ms Monica Jouhal
		            <br />
	            	Ms Vijayta
	            	<br />
		            Ms Gorika
		            <br />
		            Ms Minal Kanwar
                
                
               
              </td>
            </tr>

            <tr className="card-text text-secondary">
              <td>SEN Department </td>
              <td>
                Mr Rajesh Kumar
                <br />
                Ms Jagjeet Kaur
               
              </td>
            </tr>


          </tbody>
        </Table>
      </div>
    </Grid>
  </main>
)
